
*,
::after,
::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a:not([href]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
    -webkit-appearance: button;
    appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
    -webkit-appearance: listbox;
    appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
    appearance: none;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

::-webkit-input-placeholder {
    color: #666;
}

::-moz-placeholder {
    color: #666;
}

:-ms-input-placeholder {
    color: #666;
}

::-ms-input-placeholder {
    color: #666;
}

::placeholder {
    color: #666;
}

::-moz-selection {
    background-color: #ff4b36;
    color: #fff;
}

::selection {
    background-color: #ff4b36;
    color: #fff;
}

input,
textarea {
    outline: 0;
}

input:focus:required:invalid,
textarea:focus:required:invalid {
    border-color: red;
}

input:required:valid,
textarea:required:valid {
    border-color: green;
}

body {
    font-size: 16px;
    min-width: 320px;
    position: relative;
    line-height: 1.65;
    font-family: "Source Sans Pro", sans-serif;
    overflow-x: hidden;
    color: #08104d;
    min-height: 100vh;
    /*min-height: calc(var(--vh, 1vh) * 100);*/
    background-color: #0e0610;
}

.section-wrapper {
    max-width: 500px;
    padding: 0 20px;
    margin: 0 auto;
}

.header-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

a {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

a:hover {
    opacity: 0.8;
    text-decoration: none;
    color: #fff;
}

.header {
    padding: 20px 0;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 33;
    position: fixed;
    transition: all .3s ease
}

.header.scroll {
    background: #0E0610;
    border-bottom: 1px solid #312A54;
}

.header.scroll .header-title {
    opacity: 1;
}

.header-title {
    opacity: 0;
    transition: all .3s ease;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
    margin: 0 auto;
}

.header-back {
    margin-right: -24px;
}

.hero {
    position: relative;
}

.hero .slick-dots {
    position: relative;
    bottom: unset;
    top: unset;
    max-width: 500px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex !important;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: -50px;
    margin-bottom: 0;
}

.hero .slick-dots li {
    margin: 0;
    width: 32px;
    height: 40px;
    margin-right: 8px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.hero .slick-dots li button {
    margin: 0;
    width: 32px;
    height: 40px;
    padding: 19px 0;
    opacity: 0.3;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
    border-radius: 13px;
    font-size: 0;
}

.hero .slick-dots li button:before {
    width: 32px;
    height: 2px;
    background-color: #8d8bff;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hero .slick-dots li.slick-active button,
.hero .slick-dots li:hover button {
    opacity: 1;
}

.hero-item {
    overflow: hidden;
}

.hero-bg {
    background-image: url(./img/Stars_bgd_1@3x-1.png);
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 22;
    position: absolute;
    width: 100vw;
    height: 150%;
    top: 0;
    left: 0;
    background-size: 100%;
    pointer-events: none;
    background-repeat: no-repeat;
}

.hero-profile + .hero-bg {
    opacity: 0.4;
}

.hero-results__image {
    text-align: center;
    overflow: hidden;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.hero-results__image img {
    width: 402px;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.hero-item__wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.hero-content {
    width: 180px;
    z-index: 22;
}

.hero-image {
    width: calc(100% - 180px);
}

.hero-image img {
    width: 120%;
    min-width: 400px;
    margin-left: -40px;
}

.hero-slider {
    margin-bottom: 0 !important;
}

.hero-subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #8d8bff;
    margin-bottom: 10px;
}

.hero-title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #fff;
    margin-bottom: 25px;
}

.hero-button {
    background: -webkit-gradient(linear, right top, left top, from(#9967ff), to(#6a46e2));
    background: -o-linear-gradient(right, #9967ff 0, #6a46e2 100%);
    background: linear-gradient(270deg, #9967ff 0, #6a46e2 100%);
    border-radius: 12px;
    width: 160px;
    height: 54px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #fff !important;
}

.services {
    margin-top: 20px;
}

.services-wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.services-item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: calc(50% - 4px);
    margin-bottom: 8px;
    background: -o-linear-gradient(235.96deg, #7d77ff 0, #805eff 100%);
    background: linear-gradient(214.04deg, #7d77ff 0, #805eff 100%);
    border-radius: 12px;
    padding: 16px;
    padding-bottom: 20px;
}

.services-item:nth-child(2) {
    background: -o-linear-gradient(235.96deg, #7c46f6 0, #7f53ff 100%);
    background: linear-gradient(214.04deg, #7c46f6 0, #7f53ff 100%);
}

.services-item:nth-child(2) .services-icon {
    background-image: url(./img/icon_bgd_2@3x-1.png);
}

.services-item:nth-child(3) {
    background: -o-linear-gradient(235.96deg, #9d57fc 0, #823ff4 100%);
    background: linear-gradient(214.04deg, #9d57fc 0, #823ff4 100%);
}

.services-item:nth-child(3) .services-icon {
    background-image: url(./img/icon_bgd_3@3x-1.png);
}

.services-item:nth-child(4) {
    background: -o-linear-gradient(235.96deg, #b85bfd 0, #a13ff4 100%);
    background: linear-gradient(214.04deg, #b85bfd 0, #a13ff4 100%);
}

.services-item:nth-child(4) .services-icon {
    background-image: url(./img/icon_bgd_4@3x-1.png);
}

.services-item:nth-child(5) {
    background: -o-linear-gradient(235.96deg, #d05afd 0, #b23ff4 100%);
    background: linear-gradient(214.04deg, #d05afd 0, #b23ff4 100%);
    opacity: 0.5;
    pointer-events: none;
}

.services-item:nth-child(5) .services-icon {
    background-image: url(./img/icon_bgd_5@3x-1.png);
}

.services-item .services-icon {
    height: 71px;
    margin-top: -16px;
    margin-left: -16px;
    padding: 16px;
    width: calc(100% + 32px);
    border-radius: 12px;
    background-image: url(./img/icon_bgd_1@3x-1@2x.png);
    background-repeat: no-repeat;
    background-size: 71px;
    background-position: 0 0;
}

.services-title {
    margin-top: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #fff;
}

.services-description {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    opacity: 0.5;
    max-width: 65%;
    margin-top: 8px;
}

.footer {
    margin-top: 33px;
    margin-bottom: 35px;
}

.footer-wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 30px;
    border-top: 1px solid #1f1832;
}

.footer-nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.footer-nav ul li {
    margin-bottom: 15px;
    text-align: right;
}

.footer-nav ul li:last-child {
    margin-bottom: 0;
}

.footer-nav ul li a {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #8d8bff;
    text-decoration: none;
    display: block;
}

.footer-logo {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #fff;
}

.footer-copy {
    margin-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    opacity: 0.3;
}

.hero-profile {
    padding-top: 64px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 48px;
    border-bottom: 1px solid #1f1832;
    margin-bottom: 48px;
}

.hero-profile__title {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    color: #fff;
    margin-bottom: 4px;
    margin-top: 16px;
}

.hero-profile__zodiac {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #fff;
    margin-bottom: 16px;
}

.hero-profile__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: #fff;
    opacity: 0.6;
}

.results-block {
    margin-bottom: 56px;
}

.results-block .h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    color: #fff;
    margin-bottom: 24px;
}

.results-block p {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #fff;
    opacity: 0.8;
    margin-bottom: 30px;
}

.results-block .h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #fff;
}

.results-block img {
    margin: 24px auto;
    display: block;
    max-width: 100%;
}

.results-block ul {
    margin: 0;
    padding: 0;
}

.results-block ul li {
    padding: 0;
    position: relative;
    padding-left: 32px;
    z-index: 2;
    list-style-type: none;
    margin-bottom: 56px;
}

.results-block ul li p {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #fff;
    opacity: 0.8;
    margin-bottom: 0;
}

.results-block ul li:before {
    position: absolute;
    content: "";
    background-image: url(/img/bullet_medium_pink@3x-1.png);
    width: 28px;
    height: 28px;
    display: block;
    background-size: 28px;
    top: -2px;
    left: -3px;
}

.results-block ul li .h3 {
    margin-bottom: 28px;
    position: relative;
}

.MuiInputBase-root {
    display: flex;
    justify-content: center;
}

.results-block ul li .h3 span {
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 73px;
    color: #221c3e;
    left: -8px;
    top: 35%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    z-index: -1;
    text-transform: capitalize;
    display: block;
}

.results-block ul li .progress-line {
    margin-bottom: 24px;
    width: 100%;
    background: #221c3e;
    border-radius: 19px;
    height: 6px;
    position: relative;
}

.results-block ul li .progress-line span {
    position: absolute;
    height: 6px;
    top: 0;
    left: 0;
    width: 0;
    background: #fff;
    border-radius: 19px;
}

.results-block ul li .progress-line span.w10 {
    width: 10%;
}

.results-block ul li .progress-line span.w20 {
    width: 20%;
}

.results-block ul li .progress-line span.w30 {
    width: 30%;
}

.results-block ul li .progress-line span.w40 {
    width: 40%;
}

.results-block ul li .progress-line span.w50 {
    width: 50%;
}

.results-block ul li .progress-line span.w60 {
    width: 60%;
}

.results-block ul li .progress-line span.w70 {
    width: 70%;
}

.results-block ul li .progress-line span.w80 {
    width: 80%;
}

.results-block ul li .progress-line span.w90 {
    width: 90%;
}

.results-block ul li .progress-line span.w100 {
    width: 100%;
}

.results-block ul li.relationship:before {
    background-image: url(./img/bullet_medium_pink@3x-1.png);
}

.results-block ul li.relationship .progress-line span {
    background-color: #ff3bd4;
}

.results-block ul li.success:before {
    background-image: url(./img/bullet_medium_green@3x-1.png);
}

.results-block ul li.success .progress-line span {
    background-color: #7ed321;
}

.results-block ul li.career:before {
    background-image: url(./img/bullet_medium_blue@3x-1.png);
}

.results-block ul li.career .progress-line span {
    background-color: #479cff;
}

.results-block ul li.health:before {
    background-image: url(./img/bullet_medium_red@3x-1.png);
}

.results-block ul li.health .progress-line span {
    background-color: #ff3b62;
}

.results-block ul li.emotions:before {
    background-image: url(./img/bullet_medium_orange@3x-1.png);
}

.results-block ul li.emotions .progress-line span {
    background-color: #f5a623;
}

.results-block ul li.personal_life:before {
    background-image: url(./img/bullet_medium_mint@3x-1.png);
}

.results-block ul li.personal_life .progress-line span {
    background-color: #50e3c2;
}

.results-block ul li.profession:before {
    background-image: url(./img/bullet_medium_blue@3x-1.png);
}

.results-block ul li.profession .progress-line span {
    background-color: #479cff;
}

.results-block ul li.travel:before {
    background-image: url(./img/bullet_medium_purple@3x-1.png);
}

.results-block ul li.travel .progress-line span {
    background-color: #a644fc;
}

.results-block ul li.luck:before {
    background-image: url(./img/bullet_medium_green@3x-1.png);
}

.results-block ul li.luck .progress-line span {
    background-color: #7ed321;
}

.promo-image {
    overflow: hidden;
    max-width: 100%;
}

.promo-image img {
    width: 335px;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.quiz-intro__socials ul {
    margin-bottom: 0;
}

.quiz-input__socials__button {
    border: 1px solid #312A54;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 54px;
}

.payment_disclaimer {
    text-align: center;
    border-top: none !important;
    padding-top: 20px !important;
    margin-top: 10px !important;
    color: #7970a9;
    font-size: 14px;
}

.unsubscribe_btn{
    color: white;
}

.secure_payments_header{
    padding-bottom: 40px;
}

.results {
    padding-bottom: 70px;
    overflow: hidden;
    background: -webkit-gradient(linear, left top, left bottom, from(#0e0610), color-stop(5%, #1b1633), color-stop(95%, #1b1633), to(#0e0610));
    background: -o-linear-gradient(top, #0e0610 0, #1b1633 5%, #1b1633 95%, #0e0610 100%);
    background: linear-gradient(180deg, transparent 0, #1b1633 5%, #1b1633 95%, transparent 100%);
    position: relative;
    z-index: 22;
}

.promo {
    background-image: url(./img/Stars_bgd2.png);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 48px;
}

.promo-title {
    margin-top: -13px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: #8d8bff;
    margin-bottom: 10px;
    z-index: 2;
    position: relative;
}

.promo-subtitle {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 0;
}

.promo-description {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #fff;
    opacity: 0.6;
    margin-bottom: 24px;
}

.promo-button a {
    width: 160px;
    background: -webkit-gradient(linear, right top, left top, from(#9967ff), to(#6a46e2));
    background: -o-linear-gradient(right, #9967ff 0, #6a46e2 100%);
    background: linear-gradient(270deg, #9967ff 0, #6a46e2 100%);
    border-radius: 12px;
    height: 54px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
}

.promo-button span {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #fff;
    display: block;
}

.biorhythm-wrapper__image img {
    width: 100%;
}

.biorhythm-wrapper__image {
    height: 193px;
    margin-top: -10px;
}

.biorhythm-wrapper__bg {
    height: 193px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.biorhythm-wrapper__bg span {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #635B8C;
    opacity: 0.8;
    padding-left: 8px;
    position: relative;
    height: 33px;
    display: flex;
    align-items: center;
}

.biorhythm-wrapper__bg span:before {
    width: 100%;
    height: 1px;
    background-color: #312955;
    content: '';
    left: 0;
    position: absolute;
    bottom: 0px;
}

.biorhythm-wrapper__title {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.8;
}

.biorhythm-wrapper__prev {
    position: absolute;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #635B8C;
    opacity: 0.8;
    left: 18%;
    bottom: -22px;
    transform: translateX(18%);
}

.biorhythm-wrapper__next {
    position: absolute;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #635B8C;
    opacity: 0.8;
    right: 18%;
    transform: translateX(-18%);
    bottom: -22px;
}

.biorhythm-wrapper__image:after {
    height: 165px;
    width: 1px;
    content: '';
    display: block;
    background-color: #8d8bff;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
}

.biorhythm-wrapper__chart {
    position: absolute;
    width: 100%;
    height: 160px;
    bottom: 0;
    left: 0;
}

.hero-profile__avatar img {
    max-width: 140px;
    margin: 0 auto;
}

.biorhythm-wrapper__future {
    position: absolute;
    width: 50%;
    height: 161px;
    bottom: 0;
    right: 0;
    z-index: 22;
    background: #1B1633;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.biorhythm-wrapper__future span {
    width: 100%;
    height: 1px;
    background-color: #312955;
    left: 0;
    bottom: 0px;
    display: block;
}

.biorhythm-wrapper__chart canvas {
    width: 100%;
    height: 100%;
    border: 0;
}

.biorhythm-wrapper__image:before {
    height: 160px;
    width: 56%;
    border: 1px solid #312955;
    content: '';
    display: block;
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.biorhythm-wrapper__current {
    position: absolute;
    left: 50%;
    bottom: -28px;
    transform: translateX(-50%);
    background: #8d8bff;
    border-radius: 19px;
    padding: 4px 12px 5px 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    opacity: 1;
}

polyline {
    fill: none;
    stroke: #50E3C2;
    stroke-width: 1.5px;
}

.biorhythm-wrapper__chart {
    overflow: hidden;
}

.biorhythm-wrapper__chart svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
}

.biorhythm-wrapper__chart svg:nth-child(1) {
    margin-left: -140px;
}

.biorhythm-wrapper__chart svg:nth-child(2) {
    margin-left: -185px;
}

.biorhythm-wrapper__chart svg:nth-child(3) {
    margin-left: -195px;
}

.biorhythm-wrapper__image {
    position: relative;
}

.biorhythm .biorhythm-wrapper {
    padding-top: 56px;
    border-top: 1px solid #312a54;
}

.biorhythm .biorhythm-wrapper img {
    max-width: 100%;
}

.today-biorhythm {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 24px;
}

.today-biorhythm .today-biorhythm__col {
    width: calc(50% - 12px);
    margin-bottom: 32px;
}

.today-biorhythm .today-biorhythm__title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
}

.today-biorhythm .today-biorhythm__line {
    background: #221c3e;
    border-radius: 19px;
    position: relative;
    width: 100%;
    height: 6px;
    margin-bottom: 8px;
}

.today-biorhythm .today-biorhythm__line span {
    position: absolute;
    top: 0;
    width: 0;
    height: 6px;
}

.today-biorhythm .today-biorhythm__col.positive span {
    left: 50%;
    border-radius: 0 19px 19px 0
}

.today-biorhythm .today-biorhythm__col.negative span {
    right: 50%;
    border-radius: 19px 0 0 19px
}

.today-biorhythm .today-biorhythm__col.physical span {
    background-color: #479CFF;
}

.today-biorhythm .today-biorhythm__col.emotional span {
    background-color: #50E3C2;
}

.today-biorhythm .today-biorhythm__col.intellectual span {
    background-color: #FF3B62;
}

.today-biorhythm .today-biorhythm__col.average span {
    background-color: #A644FC;
}

.today-biorhythm .today-biorhythm__tag {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #635b8c;
    opacity: 0.8;
}

.biorhythm-title {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    color: #fff;
    margin-bottom: 24px;
}

.biorhythm-subtitle {
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #fff;
    opacity: 0.8;
}

.biorhythm {
    padding-bottom: 145px;
    background: -webkit-gradient(linear, left top, left bottom, from(#0e0610), color-stop(30%, #1b1633), color-stop(80%, #1b1633), to(#0e0610));
    background: -o-linear-gradient(top, #0e0610 0, #1b1633 30%, #1b1633 80%, #0e0610 100%);
    background: linear-gradient(180deg, #0e0610 0, #1b1633 30%, #1b1633 80%, #0e0610 100%);
}

.compatibility-block {
    margin-bottom: 60px;
}

.compatibility-block:last-child {
    margin-bottom: 0;
}

.compatibility-block__svg {
    width: 66px;
    height: 66px;
    position: relative;
    border: 4px solid #312A54;
    border-radius: 50%;
    margin-bottom: -20px;
}

.compatibility-block__svg span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    z-index: 2;
}

.compatibility-block__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    z-index: 1;
    width: 66px;
    height: 66px;
    border: 4px solid #312A54;
    border-radius: 50%;
}

.compatibility-block__svg svg {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.love .compatibility-block__svg svg path {
    stroke: #FF3B62 !important;
}

.trust .compatibility-block__svg svg path {
    stroke: #50E3C2 !important;
}

.intellect .compatibility-block__svg svg path {
    stroke: #479CFF !important;
}

.emotions .compatibility-block__svg svg path {
    stroke: #F5A623 !important;
}

.values .compatibility-block__svg svg path {
    stroke: #7ED321 !important;
}

.activities .compatibility-block__svg svg path {
    stroke: #FF3BD4 !important;
}

.summary .compatibility-block__svg svg path {
    stroke: #A644FC !important;
}

.rc-progress-circle-trail {
    stroke-width: 0px;
}

.compatibility-block__svg .inner-circle {
    stroke-dasharray: 63;
    fill: none;
    stroke-width: 3px;
    -webkit-transition: 0.4s all ease-in-out;
    -o-transition: 0.4s all ease-in-out;
    transition: 0.4s all ease-in-out;
}

.compatibility-result .compatibility-block__svg {
    margin-bottom: 0;
}

.compatibility-block__svg .outer-circle {
    fill: none;
    stroke-dashoffset: 633;
    stroke: #eee;
    stroke-width: 3px;
}

.compatibility-block__header {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 44px;
}

.compatibility-block__header .h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #fff;
    position: relative;
}

.compatibility-block__header .h3 span {
    font-style: normal;
    font-weight: 700;
    font-size: 58px;
    line-height: 73px;
    color: #221c3e;
    left: -8px;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    z-index: -1;
    text-transform: capitalize;
    display: block;
}

.compatibility-block__content:not(.active) p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.compatibility-block__content p {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #fff;
    opacity: 0.8;
    margin-bottom: 16px;
}

.compatibility-block__content.active .compatibility-block__button {
    font-size: 0;
}

.compatibility-block__content.active .compatibility-block__button:before {
    content: 'Show less';
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #8d8bff;
}

.compatibility-block__content.active .compatibility-block__button img {
    transform: rotate(180deg);
    margin-bottom: 0;
}

.compatibility-block__button {
    border: 0;
    background: 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #8d8bff;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0;
}

.compatibility-block__button img {
    margin-left: 12px;
    margin-bottom: -3px;
}

.compatibility-block .h3 {
    margin-left: 24px;
    z-index: 2;
}

button {
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.hero-compatibility {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 60px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 70px;
}

.hero-compatibility__title {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #fff;
    text-align: center;
    margin-bottom: 8px;
    z-index: 222;
    position: relative;
}

.hero-compatibility__col {
    width: 100px;
    position: relative;
    z-index: 222;
}

.hero-compatibility__col:nth-child(2) {
    width: 70px;
    height: auto;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.hero-compatibility__col:nth-child(2) .compatibility-result {
    margin-bottom: 15px;
}

.hero-compatibility__image img {
    width: 100%;
    max-width: 140px;
}

.compatibility-result {
    position: relative;
}

.compatibility-result .percent {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #fff;
}

.profile-wrapper .quiz-input {
    padding: 0;
}

.profile-wrapper .quiz-input .quiz-input__date input:first-child {
    width: calc(25% - 8px) !important;
    height: 54px;
}

.profile-wrapper .quiz-input .quiz-input__date input:nth-child(2) {
    width: calc(50% - 8px) !important;
    height: 54px;
}

.profile-wrapper .quiz-input .quiz-input__date input:nth-child(3) {
    width: calc(25% - 8px) !important;
    height: 54px;
}

.profile-wrapper .quiz-input .quiz-input__time input:first-child {
    width: calc(100% / 3 - 8px) !important;
    height: 54px;
}

.quiz-input__date input, .quiz-input input[type="number"]:hover, .quiz-input__date select {
    border: 1px solid #312A54 !important;
    padding-left: 0;
    padding-right: 0;
}

.quiz-input__date select {
    width: 100%;
}

.profile-wrapper .quiz-input .quiz-input__time input:nth-child(2) {
    width: calc(100% / 3 - 8px) !important;
    height: 54px;
}

.profile-wrapper .quiz-input .quiz-input__time input:nth-child(3) {
    width: calc(100% / 3 - 8px) !important;
    height: 54px;
}

.tutorial-profile .quiz-button {
    width: 100%;
}

.quiz-input__date select {
    box-sizing: border-box;
    border-radius: 8px;
    height: 72px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #fff !important;
    opacity: 1;
    background: none;
    color: #878388;
    text-align-last: center;
    outline: none !important;
    cursor: pointer
}

.tutorial-profile .quiz-input__time .quiz-input__date-radio:last-child {
    width: calc(100% / 3 - 8px);
}

.quiz-input__date .select-field:first-child {
    width: calc(25% - 4px) !important;
    height: 72px;
}

.quiz-input__date .select-field:nth-child(2) {
    width: calc(50% - 4px) !important;
    height: 72px;
}

.quiz-input__date .select-field:nth-child(3) {
    width: calc(25% - 4px) !important;
    height: 72px;
}

.quiz-input__date select:focus {
    color: #fff;
    opacity: 1;
    border: 1px solid #8D8BFF !important;
    outline: none !important;
}

.quiz-input__date .select-field:after {
    display: none;
}

.quiz-input__date select option {
    color: #fff;
    background-color: #100916;
    border: 0;
    padding: 0 10px;
    text-align: center;
    height: 20px;
}

.quiz-input__name {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}

.quiz-palms .quiz-processing {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    background-color: #0E0610;
}

.profile-wrapper .tutorial-payment__field .quiz-input .quiz-input__date > input {
    width: calc(100% / 3 - 4px) !important;
}

.profile-wrapper .tutorial-payment__field .quiz-input .quiz-input__date > input + .quiz-input__date-radio {
    width: calc(100% / 3 - 4px) !important;
}

.quiz-button__hidden {
    min-height: 0 !important;
    height: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    margin: 0 !important;
}

.footer + .quiz-processing,
.quiz-processing:only-child,
.quiz-processing:last-child {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    max-width: unset;
    height: 100%;
    z-index: 999;
    background-color: #0E0610;
}

.compatibility {
    color: #fff;
    height: 100vh;
}

.compatibility-inner {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;
}

.compatibility-bottom p {
    text-align: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.6;
}

.compatibility-bottom .quiz-button {
    width: 100%;
}

.compatibility-select .slick-slider {
    position: relative;
}

.compatibility-select {
    background-image: url(./img/Compatibility_bgd.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    max-height: 608px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
}

.compatibility-select .slick-prev {
    background-color: rgba(141, 139, 255, .2);
    background-image: url(./img/Back_arrow.png);
    font-size: 0;
    line-height: 0;
    background-size: 8px;
    background-position: center;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 0;
    transition: all .3s ease;
}

.compatibility-select .slick-next {
    background-color: rgba(141, 139, 255, .2);
    background-image: url(./img/next_arrow.png);
    font-size: 0;
    line-height: 0;
    background-size: 8px;
    background-position: center;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: 20px;
    right: 0;
    transition: all .3s ease;
}

.compatibility-select .slick-arrow:hover {
    transition: all .3s ease;
    background-color: rgba(141, 139, 255, 1);
}

.compatibility-select .slick-slider:last-child .slick-next,
.compatibility-select .slick-slider:last-child .slick-prev {
    top: unset;
    bottom: 20px;
}

.compatibility-item__wrapper {
    text-align: center;
}

.compatibility-item__wrapper img {
    margin: 0 auto;
}

.compatibility-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 31px;
    margin-bottom: 31px;
}

.quiz-palms .quiz-button {
    margin-bottom: 20px;
}

.quiz-palms .quiz-header__title {
    font-weight: bold;
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
    margin: 0 20px;
    margin-top: 60px;
}

.quiz-palms {
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (max-width: 479px) {
    .quiz-input__date select, .quiz-input__date .select-field {
        height: 60px !important;
    }

    .profile-wrapper .quiz-input__date select, .profile-wrapper .quiz-input__date .select-field {
        height: 54px !important;
    }

    .quiz-palms .quiz-header__title {
        margin-top: 30px;
    }

    .biorhythm-wrapper__prev {
        left: 17%;
    }

    .biorhythm-wrapper__next {
        right: 17%;
    }

    .compatibility-inner {
        padding-bottom: 20px;
    }

    .hero-image {
        width: calc(100% - 180px);
    }

    .hero-image img {
        width: 120%;
        min-width: 400px;
        margin-left: -80px;
    }

    .services-description {
        max-width: 100%;
    }

    .biorhythm-wrapper__image {
        margin: 0 -20px;
        width: calc(100% + 40px);
    }
}

@media only screen and (max-width: 320px) {
    .biorhythm-wrapper__prev {
        left: 16%;
    }

    .biorhythm-wrapper__next {
        right: 16%;
    }
}

@media only screen and (min-width: 1200px) {
    .hero-bg {
        height: 250%;
    }

    .quiz-palms__image img {
        max-width: 450px;
    }
}
strike.oldprice {
    text-decoration: none;
    /*we're replacing the default line-through*/
    position: relative;
    display: inline-block;
    /* keeps it from wrapping across multiple lines */
}

strike.oldprice:after {
    content: "";
    /* required property */
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid red;
    height: 50%;
    /* adjust as necessary, depending on line thickness */
    /* or use calc() if you don't need to support IE8: */
    height: calc(50%);
    /* 1px = half the line thickness */
    width: 100%;
}
